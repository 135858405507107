.date-range-picker {
    width: 100%;
    height: 320px;
}

.date-range-picker .rdrDefinedRangesWrapper {
    display: none;

}

.date-range-picker .rdrDayNumber {
    font-weight: bold;
}

.date-range-picker .rdrCalendarWrapper .rdrDateRangeWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.date-range-picker .rdrCalendarWrapper{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.date-range-picker .rdrMonth {
    width: 90%;
    height: 100%;
}
