
@font-face {
    font-family: 'bubblegum-sans';
    src: url('fonts/bubblegum-sans.regular.ttf');
}

@font-face {
    font-family: 'mali-semiBold';
    src: url('fonts/Mali-SemiBold.ttf');
}


body{
    margin: 0;

}


.rbc-calendar {
    font-family: Arial;
}

#page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 3.5rem;    /* Footer height */
}

.css-17p3wh3-MuiGrid-root {
    -webkit-flex-basis:100%;
    -ms-flex-preferred-size:100%;
    flex-basis:100%;
    -webkit-box-flex:0;
    -webkit-flex-grow:0;
    -ms-flex-positive:0;
    flex-grow:0;
    max-width:100%;
   }

.today-row {
    font-weight: bold;
}