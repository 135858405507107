@keyframes drive {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(50%);
    }
}

.camper-loading-icon {
    display: flex !important;
    justify-content: center;
}

.camper-loading-icon div {
    width: 50vw; /* width of the road */
}

:root {
    --animation-duration: 5s;
}

@media screen and (max-width: 1000px) {
    :root {
        --animation-duration: 3.5s;
    }
}

@media screen and (max-width: 600px) {
    :root {
        --animation-duration: 2s;
    }
}

.camper-loading-icon svg {
    animation: drive var(--animation-duration) linear infinite;
    height: 50px; /* height of the camper */
    width: 100%;
}
